import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import Question from '../Questions';
import Answer from '../Answers';
import ThankYou from '../ThankYouPage';
import ButtonContainer from './buttonContainer';
import {
  setAnswerId,
  getInstructions,
  resetQuestionNumber,
  resetOnLogin
} from '../../actions/questionActions';
import '../../assets/scss/_survey.scss';

class Survey extends Component {
  constructor(props) {
    super(props);
    const { questionNumber } = this.props;

    this.state = {
      finishSurvey: false,
      answer: 0,
      numberCount: questionNumber,
      disabled: true,
      openAnswer: '',
      multipleAnswers: [],
      multiple: false,
      showMeme: true
    };
  }

  render() {
    const { questions, complete, child } = this.props;
    const { question, questionId, instanceId, type, answers } = questions;
    const { answer, numberCount, disabled, openAnswer, multipleAnswers, multiple, showMeme, finishSurvey } = this.state;

    if (finishSurvey) return <Redirect push to="/survey-done" />;

    const sendAnswer = multiple ? multipleAnswers : answer;
    const skipped = false;
    if (complete) {
      // We completed the survey - we show the thank you page always, but only show the meme if the survey taker was a child
      return (
        <ThankYou
          submitButton={() => {
            this.props.resetOnLogin();
            this.props.resetQuestionNumber();
            this.setState({ finishSurvey: true });
          }}
          disclaimerVisible={showMeme && child}
          visibleFunction={() => this.setState({ showMeme: false })}
        />
      );
    }

    this.props.maybeGetInstructions();
    return (
      <div key={questionId} className="survey-question-container">
        <p className="survey-question">
          Question #{numberCount}
        </p>
        <Question
          questionId={questionId}
          question={question}
        />
        <Answer
          answers={answers}
          questionId={questionId}
          onChange={(text) => this.setState({ openAnswer: text })}
          type={type}
          setOneAnswer={(value) => this.setState({ disabled: false, answer: value, multiple: false })}
          setAnswer={(value, setCheck) => {
            const newAnswers = !setCheck ? [...multipleAnswers, value] : multipleAnswers.filter(b => b !== value);
            this.setState({ disabled: false, multipleAnswers: newAnswers, multiple: true });
          }}
        />
        <ButtonContainer
          disabled={disabled}
          nextFunction={() => {
            this.props.setAnswerId(questionId, sendAnswer, instanceId, skipped, openAnswer);
            this.setState((prevState) => ({ numberCount: prevState.numberCount + 1, disabled: true }));
          }}
        />
      </div>
    );
  }
}
const mapStateToProps = ({ question }) => {
  const {
    questions,
    complete,
    child,
    instructions,
    questionNumber,
    researchInstructions
  } = question;
  return {
    instructions,
    questions,
    complete,
    questionNumber,
    researchInstructions,
    child
  };
};

export default connect(mapStateToProps, {
  setAnswerId,
  getInstructions,
  resetQuestionNumber,
  resetOnLogin
})(Survey);
