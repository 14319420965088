export const buildTreatmentText = (child, childSat, guardian, guardianSat) => {
  if (child === 'Yes' && guardian === 'Yes') {
    if (childSat === 'Yes' && guardianSat === 'Yes') {
      return 'The ADOLESCENT reports they are receiving treatment for a mental health problem and the PARENT reports that the adolescent is receiving treatment for a mental health problem. The ADOLESCENT is satisfied with this treatement and the PARENT is satisfied with this treatment.'
    }
    if (childSat === 'No' && guardianSat === 'Yes') {
      return 'The ADOLESCENT reports they are receiving treatment for a mental health problem and the PARENT reports that the adolescent is receiving treatment for a mental health problem. The ADOLESCENT is not satisfied with this treatement and the PARENT is satisfied with this treatment.'
    }
    if (childSat === 'No' && guardianSat === 'No') {
      return 'The ADOLESCENT reports they are receiving treatment for a mental health problem and the PARENT reports that the adolescent is receiving treatment for a mental health problem. The ADOLESCENT is not satisfied with this treatement and the PARENT is not satisfied with this treatment.'
    }
    if (childSat === 'Yes' && guardianSat === 'No') {
      return 'The ADOLESCENT reports they are receiving treatment for a mental health problem and the PARENT reports that the adolescent is receiving treatment for a mental health problem. The ADOLESCENT is satisfied with this treatement and the PARENT is not satisfied with this treatment.'
    }
  }
  if (child === 'No' && guardian === 'Yes') {
    if (guardianSat === 'Yes') {
      return 'The ADOLESCENT reports they are not receiving treatment for a mental health problem and the PARENT reports that the adolescent is receiving treatment for a mental health problem. The PARENT is satisfied with this treatment.'
    }
    if (guardianSat === 'No') {
      return 'The ADOLESCENT reports they are not receiving treatment for a mental health problem and the PARENT reports that the adolescent is receiving treatment for a mental health problem. The PARENT is not satisfied with this treatment.'
    }
  }
  if (child === '' && guardian === 'Yes') {
    if (guardianSat === 'Yes') {
      return 'The PARENT reports that the adolescent is receiving treatment for a mental health problem. The PARENT is satisfied with this treatment.'
    }
    if (guardianSat === 'No') {
      return 'The PARENT reports that the adolescent is receiving treatment for a mental health problem. The PARENT is not satisfied with this treatment.'
    }
  }
  if (child === 'Yes' && guardian === '') {
    if (childSat === 'Yes') {
      return 'The ADOLESCENT reports that the adolescent is receiving treatment for a mental health problem. The ADOLESCENT is satisfied with this treatment.'
    }
    if (childSat === 'No') {
      return 'The ADOLESCENT reports that the adolescent is receiving treatment for a mental health problem. The ADOLESCENT is not satisfied with this treatment.'
    }
  }
  if (child === 'No' && guardian === '') {
    // if (childSat === 'Yes') {
    return 'The ADOLESCENT reports that the adolescent is not receiving treatment for a mental health problem.'
    // } if (childSat === 'No') {
    //   return 'The ADOLESCENT reports that the adolescent is receiving treatment for a mental health problem. The ADOLESCENT is not satisfied with this treatment.'
    // }
  }
  if (child === '' && guardian === 'No') {
    // if (childSat === 'Yes') {
    return 'The PARENT reports that the adolescent is not receiving treatment for a mental health problem.'
    // } if (childSat === 'No') {
    //   return 'The ADOLESCENT reports that the adolescent is receiving treatment for a mental health problem. The ADOLESCENT is not satisfied with this treatment.'
    // }
  }
  if (child === 'Yes' && guardian === 'No') {
    if (childSat === 'Yes') {
      return 'The ADOLESCENT reports they are receiving treatment for a mental health problem and the PARENT reports that the adolescent is not receiving treatment for a mental health problem. The ADOLESCENT is satisfied with this treatment.'
    }
    if (childSat === 'No') {
      return 'The ADOLESCENT reports they are receiving treatment for a mental health problem and the PARENT reports that the adolescent is not receiving treatment for a mental health problem. The ADOLESCENT is not satisfied with this treatment.'
    }
  }
  if (child === 'No' && guardian === 'No') {
    return 'The ADOLESCENT reports they are not receiving treatment for a mental health problem and the PARENT reports that the adolescent is not receiving treatment for a mental health problem.'
  }
}

export const treatment = (advanceReport) => {
  const { questions } = advanceReport;
  var child = '';
  var guardian = '';
  var childSat = '';
  var guardianSat = '';

  if (questions.child.length > 0) {
    const history = questions.child.filter((q) => q.question_name === 'treatment_history')
    child = history[0] === undefined ? "" : history[0].answer_text
    if (child === 'Yes') {
      const satisfaction = questions.child.filter((q) => q.question_name === 'treatment_satisfaction')
      childSat = satisfaction[0].answer_text
    } else {
      childSat = ''
    }
  } if (questions.guardian.length > 0) {
    const gHistory = questions.guardian.filter((q) => q.question_name === 'treatment_history_2')
    guardian = gHistory[0] === undefined ? "" : gHistory[0].answer_text
    if (guardian === 'Yes') {
      const gSatisfaction = questions.guardian.filter((q) => q.question_name === 'treatment_satisfaction_3')
      guardianSat = gSatisfaction[0].answer_text
    } else {
      guardianSat = ''
    }
  }
  return ({ child, childSat, guardian, guardianSat })
}

export const isNormalFontWeight = (val) => {
  if (val) return { fontWeight: 300 }
  return { fontWeight: 800 }
}
