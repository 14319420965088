import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import autoMergeLevel2 from 'redux-persist/lib/stateReconciler/autoMergeLevel2';
import { createStore, applyMiddleware, compose } from 'redux';
// import logger from 'redux-logger';
import thunk from 'redux-thunk';
import rootReducer from './reducers';

const config = {
  key: 'root',
  storage,
  whitelist: ['patient', 'login', 'question'],
  stateReconciler: autoMergeLevel2,
};

const reducer = persistReducer(config, rootReducer);
// const enhancer = compose(applyMiddleware(thunk, logger));
const enhancer = compose(applyMiddleware(thunk));
export const store = createStore(reducer, enhancer);
export const persistor = persistStore(store);
