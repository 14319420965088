import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import Button from '../../common/Button';
import TextField from '../../common/TextField';
import { toggleEye } from '../../actions/uiActions';
import {
  signInToAccount,
  resetLoginError,
  saveToken
} from '../../actions/loginActions';
import {
  gettingQuestion,
  resetOnLogin
} from '../../actions/questionActions';
import { setCurrentScreen } from '../../actions/historyActions';
import { getId } from '../../util/helperFunctions';
import MissingTokenView from '../MissingTokenView';
import '../../assets/scss/_login.scss';

class Login extends Component {
  constructor(props) {
    super(props);
    const { surveyToken } = this.props;
    this.props.resetOnLogin();
    this.newToken = getId();

    if (surveyToken !== this.newToken && this.newToken !== '') {
      this.props.saveToken(this.newToken)
    }
    this.state = {
      emailInvalid: true,
      passwordInvalid: true,
      email: '',
      password: '',
      showPassword: false
    };
  }

  changeEmail(e) {
    const email = e.target.value;
    this.setState({ email, emailInvalid: email.length <= 6 });
  }

  changePassword(e) {
    const password = e.target.value;
    this.setState({ password, passwordInvalid: password.length <= 6 });
  }

  handleSignInClick() {
    const { email, password } = this.state;
    this.props.resetLoginError();
    this.props.signInToAccount(email, password);
  }

  render() {
    if (this.newToken === '') return <MissingTokenView />

    const { loggedIn, report, message } = this.props;
    const {
      passwordInvalid,
      emailInvalid,
      email,
      password,
      showPassword
    } = this.state;

    if (loggedIn && report) {
      this.props.setCurrentScreen('/report');
      return <Redirect push to="/report" />;
    }
    if (loggedIn) {
      this.props.gettingQuestion(this.newToken);
      this.props.setCurrentScreen('/survey');
      return <Redirect push to="/survey" />;
    }
    return (
      <div className="background-image">
        <div className="main-view">
          <p className="login-title">
            SCREENING WIZARD
          </p>
          <p className="description">
            Please login below.
          </p>
          {message !== '' && <p className="error">{message}</p>}
          <div className="padding-top">
            <TextField
              type="text"
              placeholder="Enter Your User ID"
              value={email}
              correct={emailInvalid}
              name="email"
              data="true"
              className="text-box"
              onChange={(e) => this.changeEmail(e)}
            />
          </div>
          <div className="padding-top">
            <TextField
              type="password"
              placeholder="Enter Your Password"
              value={password}
              name="password"
              className="text-box"
              imageName="Eye"
              data="true"
              correct={passwordInvalid}
              seePassword={showPassword}
              toggleEye={() => this.setState({ showPassword: !this.state.showPassword })}
              onChange={(e) => this.changePassword(e)}
            />
          </div>
          <div className="btn-contain">
            <Button
              buttonText="Sign In"
              disabled={emailInvalid || passwordInvalid}
              onClick={() => this.handleSignInClick()}
            />
          </div>
        </div>
      </div>
    );
  }
}
const mapStateToProps = ({ ui, login, question }) => {
  const { showPassword } = ui;
  const { report } = question;
  const { message, loggedIn, surveyToken } = login;
  return {
    showPassword,
    message,
    loggedIn,
    surveyToken,
    report
  };
};

export default connect(mapStateToProps, {
  toggleEye,
  signInToAccount,
  resetLoginError,
  setCurrentScreen,
  gettingQuestion,
  saveToken,
  resetOnLogin
})(Login);
