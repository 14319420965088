import { mapQuestion, mapReport, mapAdvanced, mapComplete } from './functions';
import { answerQuestion, getReport, getAdvancedReport, getQuestion } from '../util/api';

export const setQuestionNumber = (questionNumber) => (dispatch) => {
  dispatch({ type: 'save_question_number', questionNumber });
};

export const report = (id) => (dispatch, getState) => {
  dispatch({ type: 'report_spinner' });
  const {
    login: { uid, client, token }
  } = getState();
  getReport(token, uid, client, id)
    .then((success) => {
      console.log('GET REPORT SUCCESS', success);
      const { data } = success;
      const newReport = mapReport(data);
      dispatch({ type: 'report_object', reportObject: newReport });
      dispatch({ type: 'reset_spinner' });
    }).catch((error) => {
      console.log('GET REPORT ERROR', error.message);
      dispatch({ type: 'reset_spinner' });
    });
};

export const advancedReport = (id) => (dispatch, getState) => {
  const {
    login: { uid, client, token }
  } = getState();
  dispatch({ type: 'report_spinner' });
  getAdvancedReport(token, uid, client, id)
    .then((success) => {
      console.log('GET ADVANCED REPORT SUCCESS', success);
      const { data } = success;
      const newReport = mapAdvanced(data);
      
      dispatch({ type: 'advanced_report', advanceReport: newReport });
      dispatch({ type: 'reset_spinner' });
    }).catch((error) => {
      console.log('GET ADVANCED REPORT ERROR', error.message);
      dispatch({ type: 'reset_spinner' });
    });
};

export const gettingQuestion = (id) => (dispatch, getState) => {
  dispatch({ type: 'start_spinner' });
  const {
    login: { uid, client, token }
  } = getState();
  getQuestion(token, uid, client, id)
    .then((success) => {
      console.log('GETTING SURVEY SUCCESS', success, uid, client, token, id);
      const { data } = success;
      if (data.status === 'complete') {
        const questions = mapComplete(data);
        dispatch({ type: 'set_questions', questions });
        dispatch({ type: 'complete_survey' });
      } else {
        const questions = mapQuestion(data);
        dispatch({ type: 'set_questions', questions });
        if (data.subject_type === 'child') dispatch({ type: 'set_child' });
      }
    }).catch((error) => {
      console.log('GETTING SURVEY ERROR', error.message);
    });
};

export const resetQuestionNumber = () => (dispatch) => {
  const reset = 1;
  dispatch({ type: 'save_question_number', questionNumber: reset });
};

export const resetStatus = () => (dispatch) => { dispatch({ type: 'reset_status' }); };

export const setAnswerId = (questionId, answerId, id, skipped, openAnswer) => (dispatch, getState) => {
  const {
    login: { uid, client, token }
  } = getState();
  const notSkipped = {
    answer: {
      question_id: questionId,
      answer_id: answerId,
      text: openAnswer,
      skipped: false
    }
  };
  const yesSkipped = { answer: { question_id: questionId, skipped } };
  const uploadData = skipped ? yesSkipped : notSkipped;
  answerQuestion(token, uid, client, uploadData, id)
    .then((success) => {
      console.log('ANSWER QUESTION SUCCESS', success);
      const { data } = success;
      if (data.status === 'complete') {
        const questions = mapComplete(data);
        dispatch({ type: 'set_questions', questions });
        dispatch({ type: 'complete_survey' });
      } else {
        const questions = mapQuestion(data);
        dispatch({ type: 'set_questions', questions });
      }
    })
    .catch((error) => {
      console.log('ANSWER QUESTION FAILURE', error.response, error.message);
    });
};

export const getInstructions = (instructions, researchInstructions) => (dispatch) => {
  dispatch({ type: 'question_instructions', instructions });
  dispatch({ type: 'other_instructions', researchInstructions });
};

export const resetInstructions = () => (dispatch) => {
  dispatch({ type: 'question_instructions', instructions: '' });
  dispatch({ type: 'other_instructions', researchInstructions: '' });
};

export const getQuestions = (participants, patientId, parentGuardian) => (dispatch) => {
  participants.map((item) => {
    if (item.patientId === patientId) {
      if (parentGuardian === 'patient') {
        const questions = Object.assign(item.childQuestions);
        dispatch({ type: 'set_questions', questions });
      } else {
        const questions = Object.assign(item.guardianQuestions);
        dispatch({ type: 'set_questions', questions });
      }
    } return null;
  });
};


export const acceptDisclaimer = () => (dispatch) => {
  dispatch({ type: 'accept_disclaim' });
};

export const acceptMeme2 = () => (dispatch) => {
  dispatch({ type: 'accept_meme2' });
};

export const setReport = () => (dispatch) => {
  dispatch({ type: 'set_report' });
};

export const resetReport = () => (dispatch) => {
  dispatch({ type: 'reset_report' });
}

export const resetOnLogin = () => (dispatch) => {
  const clear = [];
  dispatch({ type: 'reset_log_in' });
  dispatch({ type: 'participant_list', participants: clear });
  dispatch({ type: 'reset_login_error' });
  dispatch({ type: 'reset_status' });
  dispatch({ type: 'question_instructions', instructions: '' });
  dispatch({ type: 'other_instructions', researchInstructions: '' });
  dispatch({ type: 'reset_disclaim' });
  dispatch({ type: 'reset_meme2' });
  dispatch({ type: 'reset_meme3' });
  dispatch({ type: 'save_question_number', questionNumber: 1 });
  dispatch({ type: 'reset_child' });
  dispatch({ type: 'reset_report' });
  dispatch({ type: 'set_questions', questions: {} });
}
