import React from 'react';
import Button from '../../common/Button';
import Disclaimer from '../Disclaimer';
import '../../assets/scss/_survey.scss';

const ThankYou = (props) => {
  const disclaimerComp = props.disclaimerVisible && <Disclaimer
    visible
    memeInfo="meme4"
    memes
    parentGuardian="child"
    visibleFunction={() => props.visibleFunction()}
  />

  return (
    <div className="thank-you-container">
      {disclaimerComp}
      <div className="thank-you-container">
        <p className="thank-you-text">
          Thank you for taking this survey!
        </p>
        <p className="thank-you-text">
          You may click Submit and close the browser window.
        </p>
        <div className="submit-btn-padding">
          <Button
            disabled={false}
            buttonText="Submit"
            onClick={() => props.submitButton()}
          />
        </div>
      </div>
    </div>
  )
}

export default ThankYou;
